import { ArcsLayer } from '@nivo/arcs';
import { useInheritedColor } from '@nivo/colors';
import { useMemo, useRef } from 'react';
import { animated } from '@react-spring/web';
import { Tooltip } from '@chakra-ui/react';

/** Arcs that are displayed by default, each arc represents a theme */
export const MainArcs = ({
	arcGenerator,
	centerX,
	centerY,
	dataWithArc,
	onThemeClick,
	selectedFilter,
}) => {
	const ref = useRef(null);

	const fadeColor = useInheritedColor({
		from: 'color',
		modifiers: [['opacity', 0.4]],
	});

	const data = useMemo(
		() =>
			/** If a filter is selected (such as a theme or a department), fade out the arc that does not match the value of the current filter. */
			selectedFilter
				? dataWithArc.map((datum) =>
						selectedFilter?.value === datum.id ? datum : { ...datum, color: fadeColor(datum) }
				  )
				: dataWithArc,
		[dataWithArc, fadeColor, selectedFilter]
	);

	const handleArcClick = (datum) => {
		if (selectedFilter?.value === datum.id) {
			onThemeClick(null);
		} else {
			onThemeClick(datum.id);
		}
	};

	return (
		<g ref={ref}>
			<ArcsLayer
				center={[centerX, centerY]}
				data={data}
				arcGenerator={arcGenerator}
				borderWidth={0}
				borderColor='transparent'
				transitionMode='innerRadius'
				onClick={handleArcClick}
				component={(props) => <ArcShape {...props} onClick={handleArcClick} />}
			/>
		</g>
	);
};

/** Arcs used for partial highlighting. Each arc represents a theme, this arc can be partially highlighted depending on how
 * many of the selected risks have this theme
 */
export const HighlightArcs = ({
	highlightedData,
	selectedFilter,
	arcGenerator,
	centerX,
	centerY,
	dataWithArc,
	onThemeClick,
	radius,
	innerRadius,
}) => {
	const ref = useRef(null);

	const fadeColor = useInheritedColor({
		from: 'color',
		modifiers: [['opacity', 0.4]],
	});

	const handleArcClick = (datum) => {
		if (selectedFilter?.value === datum.id) {
			onThemeClick(null);
		} else {
			onThemeClick(datum.id);
		}
	};

	const data = useMemo(
		() =>
			dataWithArc.map((datum) => {
				let outerRadius = 0;

				const highlightedArc = highlightedData.find((entry) => entry.id === datum.data.id);

				if (highlightedArc) {
					/** The difference between the outer radius and inner radius determines the width of the full arc */
					const arcWidth = radius - innerRadius;

					/** Calculate the outer radius of the highlighted arc */
					outerRadius = innerRadius + (highlightedArc.value / datum.data.value) * arcWidth;
				}

				return {
					...datum,
					arc: { ...datum.arc, outerRadius, innerRadius: !outerRadius ? 0 : innerRadius },
				};
			}),
		[dataWithArc, highlightedData, fadeColor]
	);

	return (
		<g ref={ref}>
			<ArcsLayer
				center={[centerX, centerY]}
				data={data}
				arcGenerator={arcGenerator}
				borderWidth={0}
				borderColor='transparent'
				transitionMode='innerRadius'
				component={(props) => <ArcShape {...props} onClick={handleArcClick} />}
			/>
		</g>
	);
};

const ArcShape = ({ style, datum, onClick }) => {
	return (
		<Tooltip label={`${datum.data.label}: ${datum.data.value}`}>
			<animated.path
				d={style.path}
				opacity={style.opacity}
				fill={datum.fill || style.color}
				stroke={style.borderColor}
				strokeWidth={style.borderWidth}
				onClick={() => onClick(datum)}
				cursor='pointer'
			/>
		</Tooltip>
	);
};
