import { Tooltip } from '@chakra-ui/react';
import { useMemo, useRef } from 'react';

export const MainBarsLayer = ({ bars, selectedFilter, onDepartmentClick }) => {
	const ref = useRef(null);

	const handleBarClick = (bar) => {
		if (selectedFilter?.value === bar.data.data.department) {
			onDepartmentClick(undefined);
		} else {
			onDepartmentClick(bar.data.data.department);
		}
	};

	return (
		<g>
			{bars.map((bar, index) => (
				<Tooltip label={`${bar.data.data.label}: ${bar.data.data.value}`}>
					<rect
						ref={ref}
						key={`bar-${index}`}
						x={bar.x}
						y={bar.y}
						cursor='pointer'
						width={bar.width}
						height={bar.height}
						fill={FILL_COLOR}
						onClick={() => handleBarClick(bar)}
						opacity={selectedFilter && selectedFilter.value !== bar.data.data.department ? 0.5 : 1}
					/>
				</Tooltip>
			))}
		</g>
	);
};

/** Bars used for partial highlighting. Each bar represents a department, this bar can be partially highlighted depending on how
 * many of the selected risks have this department
 */
export const HighlightBarsLayer = ({
	bars,
	xScale,
	yScale,
	innerHeight,
	highlightedData,
	selectedFilter,
	onDepartmentClick,
}) => {
	return (
		<g>
			{bars.map((bar, index) => {
				return (
					<HighlightBar
						key={`highlighted-bar-${index}`}
						bar={bar}
						highlightedData={highlightedData}
						xScale={xScale}
						yScale={yScale}
						innerHeight={innerHeight}
						index={index}
						selectedFilter={selectedFilter}
						onDepartmentClick={onDepartmentClick}
					/>
				);
			})}
		</g>
	);
};

export const HighlightBar = ({
	bar,
	highlightedData,
	xScale,
	yScale,
	innerHeight,
	selectedFilter,
	onDepartmentClick,
}) => {
	const highlightedBar = useMemo(() => {
		return highlightedData.find((entry) => entry.id === bar.data.data.id);
	}, [highlightedData]);

	const height = useMemo(() => {
		if (highlightedBar) {
			/** yScale() determines the height of the bar depending on the provided value.
			 * Subtracting the actual height from yScale(0) ensures that the bar always starts from the right position
			 */
			const height = yScale(0) - yScale(highlightedBar.value);
			return height;
		}
	}, [highlightedBar, bar]);

	const handleBarClick = (bar) => {
		if (selectedFilter?.value === bar.data.data.department) {
			onDepartmentClick(undefined);
		} else {
			onDepartmentClick(bar.data.data.department);
		}
	};

	return (
		<Tooltip label={`${bar.data.data.label}: ${bar.data.data.value}`}>
			<rect
				overflow='hidden'
				x={bar.x}
				y={innerHeight - height}
				width={xScale.bandwidth()}
				height={height}
				fill={FILL_COLOR}
				cursor='pointer'
				onClick={() => handleBarClick(bar)}
			/>
		</Tooltip>
	);
};

const FILL_COLOR = '#118dff';
